import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../Theme/layout';
import styles from './index.module.scss';
import SEO from '../components/seo';
import Header from '../components/pages/home/Header/Header';
import ArticleItem from '../components/ArticleItem/ArticleItem';
import BigArticleTitle from '../components/shared/texts/BigArticleTitle/BigArticleTitle';


const IndexPage = ({ data }) => {
  const { allDatoCmsArticle: { nodes: articles } } = data;
  return (
    <Layout>
      <SEO title="Twój grecki"/>
      <Header/>
      <BigArticleTitle className={styles.articlesTitle}>Artykuły</BigArticleTitle>
      {articles.map((article, index) => {
        if (article.promotedOnFrontPage) {
          if (index % 2 === 0) {
            return <ArticleItem key={article.id} id={article.id} img={article.miniature.fluid} title={article.title}
                                shortDescription={article.shortDescription}/>;
          } else {
            return <ArticleItem key={article.id} id={article.id} img={article.miniature.fluid} title={article.title}
                                shortDescription={article.shortDescription} reverse/>;
          }
        } else {
          return null;
        }
      })}
    </Layout>
  );
};
export const query = graphql`
  {
    allDatoCmsArticle {
      nodes{
        title
        promotedOnFrontPage
        shortDescription
        id
        miniature {
          alt
            fluid(maxWidth: 900) {
                ...GatsbyDatoCmsFluid_tracedSVG
            }
        }
      }
    }
  }
`;

export default IndexPage;
