import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './Header.module.scss';
import Image from 'gatsby-image';
import ArticleTitle from '../../../shared/texts/ArticleTitle/ArticleTitle';
import BigArticleTitle from '../../../shared/texts/BigArticleTitle/BigArticleTitle';
import Paragraph from '../../../shared/texts/Paragraph/Paragraph';
import {StaticQuery, graphql, Link} from 'gatsby';
import cs from 'classnames';


class Header extends Component {
  state = {
    isDogActive: false,
    animationActive: false
  };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ isDogActive: true, animationActive: true });
    }, 1000);
  }

  render() {
    const { data } = this.props;
    const { file: { childImageSharp: { fluid } } } = data;
    const { isDogActive, animationActive } = this.state;
    return (
      <header className={styles.header}>
        <div className={styles.content}>
            <ArticleTitle className={cs(styles.title, {[styles.animTitle]: animationActive}, {[styles.animBeam]: animationActive})} data-atr="NAUCZ&nbsp;SIĘ">NAUCZ SIĘ</ArticleTitle>
            <ArticleTitle className={cs(styles.title, {[styles.animTitle]: animationActive}, {[styles.animBeam]: animationActive})} data-atr="JĘZYKA&nbsp;GRECKIEGO">JĘZYKA GRECKIEGO</ArticleTitle>
            <BigArticleTitle className={cs(styles.bigTitle, {[styles.animTitle]: animationActive}, {[styles.animBeam]: animationActive})} data-atr="OD&nbsp;PODSTAW">OD PODSTAW</BigArticleTitle>
            <div className={styles.descriptionWrap}>
                <Paragraph className={cs(styles.paragraph, styles.boxLink,  'wow', 'animate__fadeInUp')} data-wow-duration=".6s">
                <Link className={styles.registerLessonLink} to="/#kontakt">Umów się na lekcję</Link>
                </Paragraph>
                <Paragraph className={cs(styles.paragraph, 'wow', 'animate__fadeInUp')} data-wow-duration=".6s">
                  Tutaj nauczysz się podstaw języka greckiego i znajdziesz ciekawe lekcje na dobry start.
              </Paragraph>
              <Paragraph className={cs(styles.paragraph, 'wow', 'animate__fadeInUp')} data-wow-duration=".6s">
                  Przeczytasz i posłuchasz o greckich wyspach i wszystkim co z nimi związane.
              </Paragraph>
            </div>
        </div>
        <div className={cs(styles.dogWrap, { [styles.isActive]: isDogActive })}>
          <Image className={styles.dog} fluid={fluid} alt="Grecki"/>
        </div>
      </header>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        file (name: {eq: "grecki1"}, childImageSharp: {fixed: {}}){
          childImageSharp{
            fluid {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)


Header.propTypes = {
  data: PropTypes.shape({
    file: PropTypes.shape({
      childImageSharp: PropTypes.shape({
        fluid: PropTypes.object.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
};
